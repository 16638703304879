/** @format */

import { AdultCourse } from "../../../pages";

const EN_TRANSLATE = {
  Company_name: "Goodlife., Ltd",
  Business_Registration: "Business Registration",
  Business_License:
    "Business registration number: 0110496704 issued by Hanoi City Business Registration Office on 22/02/2024",
  Business_Qualification_License:
    "Business eligibility license: 217 /GCN-SVHTT issued by Hanoi Department of Culture and Sports on 07/06/2024",
  // HomePage
  HomePage_welcome: "Welcome",
  HomePage_BannerTitle: "CREATING A SWIMMING CULTURE IN HANOI",
  Homepage_WelcomeBanner:
    "Welcome to Aquatics Hub, a modern 8-lane, 25-meter ​pool in Hanoi, catering to swimmers of all ages and skill ​levels, promoting lifelong aquatic enjoyment and ​wellbeing for individuals and families.",
  // Trinhtn fix từ đây
  HomePage_Course: "COURSE",
  HomePage_SwimClass: "SWIM CLASSES",
  HomePage_AdultCourses: "ADULT COURSES",
  HomePage_SwimClub: "SWIM CLUB",
  HomePage_Rental: "RENTAL",
  HomePage_LaneRental: "LANE RENTAL",
  HomePage_PoolRental: "POOL RENTAL",
  HomePage_ContactRental: "CONTACT",
  HomePage_Contact: "CONTACT",
  HomePage_schedule: "Schedule - from monday 19th august 2024",
  // AdultCourse
  AdultCourse_register: "REGISTER INTEREST",
  AdultCourse_CominginJune: "COMING IN JUNE",
  AdultCourse_Home: "HOME",
  AdultCourse_FutureCourse: "Future courses",
  AdultCourse_beginner: "BEGINNER SWIMMING",
  AdultCourse_beginner_Description:
    "An excellent class for adults who wish to become more comfortable in the water and learn basic strokes. Our instructors work at you pace.",
  AdultCourse_weekly: "Weekly:",
  AdultCourse_timely: "Mondays, Tuesdays, Thursdays & Fridays at 19:30-20:30",
  AdultCourse_Technique: "TECHNIQUE SWIMMING",
  AdultCourse_Technique_Description:
    "A class for adult swimmers who have basic swimming but would like to develop their technique for better efficiency through the water.",
  AdultCourse_Master: "MASTERS SWIMMING",
  AdultCourse_Master_Description:
    "A class for swimmers who are proficient in multiple strokes and generally swim with good technique. Training off sets is primarily to improve endurance and coordination resulting in further distances and quicker swimming.",
  AdultCourse_Session: "SESSIONS",
  // PublicSwiming
  PublicSwiming_OnOffer: "ON OFFER",
  PublicSwiming_OpenPrice: "OPEN SWIMMING PRICE",
  PublicSwiming_Age: "AGE",
  PublicSwiming_CommingLater: "coming later",
  PublicSwiming_ticket: "TICKET",
  // PublicSwiming_purchasex10: "PURCHASE 10X TICKET",
  PublicSwiming_purchasex10: "Buy tickets at the reception desk",
  PublicSwiming_Free: "FREE",
  PublicSwiming_LanePrice: "LANE SWIMMING PRICE",
  PublicSwiming_Schedule: "SCHEDULE",
  PublicSwiming_Policy: "Aquatics Hub Unaccompanied Swimmer Policy",
  PublicSwiming_Public: "PUBLIC SWIMMING",
  PublicSwiming_Public_Description:
    "Explore aquatic enjoyment at Aquatics Hub! Whether it's lap swimming or a fun family day, we cater to all. Join our public sessions for a vibrant swimming community experience.",
  PublicSwiming_Lane: "LANE SWIMMING",
  PublicSwiming_Lane_Description:
    "Throughout the week we offer lane swimming for fitness and training. You choose the lane that suits your speed, then join in. At least four lanes are available during these sessions.",
  PublicSwiming_Open: "OPEN SWIM",
  PublicSwiming_Open_Description:
    "Open Swimming is for all ages and abilities. Come with friends and family to swim in an informal and leisurely environment.",
  PublicSwiming_Adult: "ADULT",
  PublicSwiming_Child: "CHILD(3-16YRS)",
  PublicSwiming_Price: "PRICE",
  // Swimming Lesson
  SwimmingCourse_School: "SCHOOL OF FISH LEVELS",
  SwimmingCourse_Level: "WHICH LEVEL ARE YOU ?",
  SwimmingCourse_Summer2024: "OPEN CLASSES - SUMMER 2024",
  SwimmingCourse_Book: "Book Now",
  SwimmingCourse_ENQUIRE: "ENQUIRE",
  SwimmingCourse_Sep2024: "COMING - SEPTEMBER 2024",
  SwimmingCourse_CourseforU: "Course for you !!!",
  //Club
  Club_FlyingSquad: "FLYING FISH SQUADS",
  Club_RequestTrial: "REQUEST A TRIAL",
  Club_MorePricing: "MORE ON PRICING",
  Club_ViewWeekSession: "VIEW WEEKLY SESSION SCHEDULE",
  Club_SessionDateCost: "Session Dates and Cost",
  Club_FlyingFishSche: "FLYING FISH SCHEDULE",
  Club_Monday: "MONDAY",
  Club_Tue: "TUESDAY",
  Club_wed: "WEDNESDAY",
  Club_Th: "THURSDAY",
  Club_Fri: "FRIDAY",
  Club_Sa: "SATURDAY",
  Club_Su: "SUNDAY",
  Club_purple: "PURPLE",
  Club_bronze: "BRONZE",
  Club_silver: "SILVER",
  Club_Gold: "GOLD",
  Club_Dev: "DEVELOPMENT",
  Club_Elitie: "ELITE",

  // Modal request trial

  modal_request_trial_label_email: "Email",
  modal_request_trial_label_phone: "Phone",
  modal_request_trial_label_name: "Name",
  modal_request_trial_label_request: "Request",

  // Lane Rental
  lane_rental_title: "LANE & POOL ​RENTAL",
  lane_rental_description:
    " Aquatics Hub offers more than just a ​pool; it's your aquatic canvas for ​events, parties, and even personal ​training. Rent our versatile 8-lane, 25m ​pool for everything from celebrations ​to year-round lane rentals.",
  lane_rental_contact: "Contact",
  lane_rental_lane_hire: "LANE HIRE - AVAILABLE FROM JULY 2024",
  lane_rental_pool_price: "POOL PRICE - FROM 5,000,000/ HOUR",
  lane_rental_home: "Home",

  // Aquarium
  aquarium_price: "AQUARIUM PRICE",
  aquarium_register: " REGISTER INTEREST",
  aquarium_home: " HOME",
  // Notification

  //  Footer
  title_footer: "Class for you",
  description_footer: "CONTACT US",
  title_button_footer: "Find a class for me",
  footer_bottom_Name: "Swim Pool",
  Footer_bottom_Name_description:
    "Swim Academy is passionate about sports and physical activity.Our school was created out of passion for water sports and personal development.",
  Footer_bottom_Location: "Location",
  Footer_bottom_Hours: "Hours",
  Footer_bottom_time_1: "Everyday: 6:00 - 22:00",
  Footer_bottom_time_2: "Sat: 8:00 - 21:00",
  Footer_bottom_time_3: "Sun: 8:00 - 20:00",
  Footer_bottom_Explore: "Explore",
  Footer_bottom_Ourteam: "Our Team",
  Footer_bottom_AboutUs: "About Us",
  Footer_bottom_Service: "Service",
  Footer_bottom_DiscoverBenefit: "Discover Benefit",
  Footer_bottom_Resource: "Resource",
  Footer_bottom_copyright: "by TECHFIS SOLUTION",
  Footer_ward: "Ward ",
  Footer_district: "District ",
  Footer_bottom_policy: "Policy",
  Footer_bottom_Information_security_policy: "Information security policy",
  Footer_bottom_complaint_response_policy: "Complaint response policy",
  Footer_bottom_Payment_policy: "Payment policy",
  Footer_bottom_refund_policy: "Exchange, cancellation and refund policy",
  Footer_bottom_Online_purchasing_policy: "Online purchasing policy",
  Footer_bottom_Accept_Payments: "Accept Payments",
  Footer_bottom_contact: "Contact",
  footer_bottom_service_Course: "Swim Course",
  // Private Header
  h_pr_Name: "Pool Management",
  h_pr_placeholder_search: "Search...",
  h_pr_Logout: "Log Out",

  // Public Header
  h_pu_phone: "Phone number",
  h_pu_email: "Email",
  h_pu_placeholder_search: "Search...",
  h_pu_signup: "Sign Up",
  h_pu_signin: "Sign In",

  // Navigation
  nav_Home: "HOME",
  nav_Pages: "CATALOGS",
  nav_Booking: "BOOKING",
  nav_Contact: "CONTACT",
  nav_view_profile: "View Profile",
  nav_my_learning: "My Learning",
  nav_sign_out: "Sign Out",
  subnav_swimmingleague: "SWIMMING LEAGUE",
  subnav_aboutUs: "ABOUT US",
  subnav_ourTeam: "OUR TEAM",
  subnav_allCourse: "ALL COURSE",
  subnav_allpoollane: "ALL POOL LANE",
  subnav_waterAerobics: "WATER AEROBICS",
  nav_qrwallet: "QR Wallet",

  // Private Sidebar
  pv_sidebar_dashboard: "Dashboard",
  pv_sidebar_timetable: "Time Table",
  pv_sidebar_manage_student: "Manage Student",
  pv_sidebar_student_list: "Student List",
  pv_sidebar_managecourse: "Manage Course",
  pv_sidebar_courselist: "Course List",
  pv_sidebar_instructor: "Instructor",
  pv_sidebar_personprofile: "Person Profile",

  // Card Pool
  btn_booknow: "Book Now",

  // Note Teacher
  note_teacher_Delete_note: "Delete Note",
  note_teacher_add_on: "Add on",
  note_teacher_by: "By",

  // Explore Class
  explore_class_werook: "We Rock",
  explore_class_title: "Explore Classes",
  explore_class_description:
    "Whether you want to introduce your infant to the water or are an adult looking to learn to swim, we offer a variety of swimming classes for all skill levels.",
  explore_class_seemore: "See more class. Go to the",
  explore_class_fulllist: "full list",

  //Introduce Service
  is_welcome: "Welcome to our pool",
  is_welcome_description:
    "We understand that as a parent, your child's safety is paramount. At Swim Academy, we believe your swimmers deserve to be in the best hands at all times, which is why our instructors go through an extensive recruitment process and a comprehensive training period. In addition, we always try to keep our classes small and provide a maximum number of instructors.",
  is_welcome_who: "Who we are",
  is_welcome_who_description:
    " We are an open and friendly swimming school. Each of our little floats is special and unique to us. We know each of our students not only by name, but most of all we listen to them and try to get to know them we",
  is_welcome_ourprogram: " Our program",
  is_welcome_ourprogram_description:
    "We are an open and friendly swimming school. Each of our little floats is special and unique to us. We know each of our students not only by name, but most of all we listen to them and try to get to know them we",
  is_welcome_about: "About Swimming Club",
  is_welcome_about_description:
    "We understand that as a parent, your child's safety is paramount. At Swim Academy, we believe your swimmers deserve to be in the best hands at all times, which is why our instructors go through an extensive recruitment process and a comprehensive training period. In addition, we always try to keep our classes small and provide a maximum number of instructors.",
  is_welcome_ourhistory: "Our history",
  is_welcome_ourhistory_description:
    "We are an open and friendly swimming school. Each of our little floats is special and unique to us. We know each of our students not only by name, but most of all we listen to them and try to get to know them we",
  is_welcome_misson: "Our mission",
  is_welcome_misson_description:
    "We are an open and friendly swimming school. Each of our little floats is special and unique to us. We know each of our students not only by name, but most of all we listen to them and try to get to know them we",
  is_welcome_tojoin: "How to join",
  is_welcome_tojoin_description:
    "We are an open and friendly swimming school. Each of our little floats is special and unique to us. We know each of our students not only by name, but most of all we listen to them and try to get to know them we",

  //Join To Day
  jointoday_title: "Join To Day",
  jointoday_description: `Let the Aquatics Hub provide your school with qualified swimming teachers and a progressive swim curriculum. Our team of trained professional swimming teachers can teach 'learn to swim' or 'stroke development' for your school. The lessons are a cost-effective way of providing your pupils with the very best swimming lessons. All our teachers are fully certified and attended our own School of Fish courses.

    Weekly morning and early afternoon slots are available during term time.
    3,000,000 per hour (maximum 40 students)`,
  jointoday_title_bottom: "School Group",
  jointoday_des_01: "Enthusiasm",
  jointoday_des_02: "Friendly",
  jointoday_des_03: "Thoughtful",
  jointoday_des_04: "Experience",
  jointoday_des_05: "Conscientious",
  jointoday_des_06: "Quality",
  // Home Page
  HomePage_button_banner_1: "SEE HOW TO SIGN UP",
  HomePage_button_banner_2: "CONTACT",
  HomePage_flash_TitleTop: "IT'S A GOOD DAY",
  HomePage_flash_TitleBottom: "We Will Help You Achive Your Goal",
  HomePage_flash_desctiption:
    " We put emphasis above all on making our classes interesting, diverse and unique,and at the same time bringing new swimming skills and personal development.",
  HomePage_wellcome: "Welcome to our swimming pool lane",
  HomePage_Expore_pool_title: "Explore Pool Lane",
  HomePage_Expore_pool_description:
    " Whether you want to introduce your infant to the water or are an adult looking to learn to swim, we offer a variety of swimming classes for all skill levels.",
  HomePage_Expore_pool_seemore: "See more Pool lane. Go to the",
  HomePage_Expore_pool_fulllist: "Details",
  HomePage_Expore_passion_title: "PASSION & SKILLS",
  HomePage_Expore_passion_title_bottom: "Meet the teacher",
  HomePage_Expore_passion_description:
    "Our school currently has a dozen qualified swimming instructors. We all have one thing in common: a passion for sport and a unique approach to children.",

  // Pool
  // Card Pool
  card_pool__more_info: "More information",

  // Pool All
  poolall_title_top_banner: "SWIM LESSONS FOR ADULTS AND CHILDREN",
  poolall_title_bottom_banner: "WHERE TO FAMILIES LEARN TO SWIM",
  poolall_title_description_banner:
    "Welcome to Happy pool, where every day is a pool day! Our vibrant and exciting atmosphere is the perfect place to relax, swim, and take a break from your busy life. Come join us for some fun in the sun!",
  // Pool Detail
  pooldetail_about: "About",
  pooldetail_booknow: "Book Now",

  // Login
  login_username: "Username",
  login_wrong_email: "Wrong email format",
  login_signin: "Sign In",
  login_signup: "Sign Up",
  login_valid_number: "Must be only digits",
  login_valid_required: "Required!",
  login_password_not_match: "Password's not match",
  signIn_success: "Signin successful",
  signUp_success: "SignUp successful",
  signup_firstname: "First Name",
  signup_lastname: "Last Name",
  sign_phone: "Phone",
  sign_password: "Password",
  signup_confirmpassword: "Confirm Password",
  signup_age: "Age",
  signup_dateofbirth: " YYYY-MM-DD",
  signup_address: "Address",
  signup_already: "Already have an account ?",
  signIn_have_account: "Do not have an account ?",
  sign_student: "Student",
  sign_lecturer: "Lecturer",
  sign_employee: "Staff",

  login_valid_age: "Invalid Age",
  login_valid_Incorrect_credentials: "Incorrect credentials",
  login_valid_user_exits: "User exists",

  // Error Page
  error_page_back: "Back To Home",

  // Check Out
  check_out_title: "Checkout",
  check_out_address_title: "Billing address",
  check_out_address_conutry: "Country",
  check_out_require: "Required",
  check_out_note:
    "Pool school is required by law to collect applicable transaction taxes for purchases made in certain max jurisdictions",
  check_out_method_title: "Payment method",
  check_out_secured: "Secured connection",
  check_out_order_title: "Order Detail",
  check_out_Summary: "Summary",
  check_out_price: "Original Price",
  check_out_transaction_fee: "Transaction fee",
  check_out_total: "Total",
  check_out_purchase_title: "By completing your purchase you agree to these",
  check_out_term: "Terms of Service",
  check_out_next: "Next",
  check_out_money_back: "30-Day Money-Back Guarantee",
  check_out_service_charge: "Service Charge",
  check_out_cash:
    "Cash - Pay at the reception desk as soon as possible to secure your place",
  check_out_vnpay_recommend: "Recommend payment by QR",
  enroll_successful: "Enroll successful",
  payments_error:
    "Payment error, please try again. If the issue persist, don't hesitate to contact us",
  error_checkout: "Something has occured, please contact admin",
  // Course Detail Public
  pu_course_detail_startdate: "Start Date",
  pu_course_detail_enddate: "End Date",
  pu_course_detail_length: "Length",
  pu_course_detail_duration: "Duration",
  pu_course_detail_overview: "Overview",
  pu_course_detail_book_class: "Book a class",
  pu_course_detail_aready: "You has already enrolled",
  pu_course_detail_student: "STUDENTS",
  pu_course_detail_course: "COURSE",
  pu_course_detail_location: "LOCATION",
  pu_course_detail_lecturer: "LECTURER",
  pu_course_detail_schedule: "Class Schedule",
  pu_course_detail_full_continue: "Class registration is full, continue ?",
  pu_course_register_full: "Class registration is full",
  pu_course_detail_me_enroll: "Enroll Course",
  pu_course_detail_me_sure: "Are you sure to enroll this course?",
  pu_course_detail_me_yes: "Yes",
  pu_course_detail_me_no: "No",
  pu_course_detail_registration_confirmation: "Registration confirmation",
  pu_course_detail_registration_confirmation_desc:
    "Are you sure complete your registration ?",

  // My Learning
  my_learning_title: "My Learning",
  my_learning_not_learn:
    "You currently do not have any courses. Please enrolled a new",

  // Course Enroll Detail
  ced_overview: "OVERVIEW",
  ced_overview_description: "Overview description",
  ced_instructor: "INSTRUCTOR",
  ced_faq: "FAQ",
  ced_review: "REVIEW",
  ced_enter_your_review: "Enter your review",
  ced_btn_send: "Send",
  ced_review_course_title: "Your comments about the course",
  ced_timetable: "TIME TABLE",
  // User Profile
  user_profile_info: "Personal information",
  user_profile_secure_setting: "Security Settings",
  user_profile_edit_profile: "Edit Profile",
  user_profile_info_title: "Personal Infomation",
  user_profile_info_title_bottom:
    "Basic info, like your name and address, that use",
  user_profile_info_basic_title: "Basic",
  user_profile_info_basic_full_name: "Full Name",
  user_profile_info_basic_first_name: "First Name",
  user_profile_info_basic_last_name: "Last Name",
  user_profile_info_basic_gender: "Gender",
  user_profile_info_basic_email: "Email",
  user_profile_info_basic_phone: "Phone Number",
  user_profile_info_basic_birth: "Date of birth",
  user_profile_info_basic_address: "Address",
  user_profile_secure_setting_title: "Security Settings",
  user_profile_secure_setting_title_bottom:
    "These settings are helps you keep your account secure.",
  user_profile_secure_setting_change_title: "Change Password",
  user_profile_secure_setting_change_title_bottom:
    "Set a unique password to protect your account.",
  user_profile_secure_setting_change_lasttime: "last change",
  user_profile_update_profile_title: "Update Profile",
  user_profile_update_profile_title_bottom:
    "These settings are helps you keep your update profile.",
  user_profile_change_avatar: "Change avatar",
  user_profile_mypurchase: "My Service",

  user_profile_type: "Type: ",
  user_profile_start: "Start date: ",
  user_profile_end: "End date: ",
  user_profile_status: "Status: ",

  user_profile_family_member: "Family member",
  user_profile_family_member_fullname: "Full name",
  user_profile_family_member_dob: "Date of birth",
  user_profile_family_member_gender: "Gender",
  user_profile_family_member_course: "Course",
  user_profile_family_member_table_des: "Description",
  user_profile_family_member_table_classnumber: "Class Number",
  user_profile_family_member_table_historynumber: "History Number",
  user_profile_family_member_table_type: "Type",
  user_profile_family_member_table_status: "Status",
  user_profile_family_member_table_payments: "Payments",
  user_profile_family_member_table_createdate: "Create date",
  user_profile_family_member_table_view: "View",

  user_profile_my_learning: "My Learning",
  // Teacher Profile
  teach_profile_phone: "Phone Number",
  teach_profile_email: "Email",
  teach_profile_about: "ABOUT",
  teach_profile_course: "Course",
  teach_profile_review: "Reviews",
  teach_profile_review_student: "Student",
  teach_profile_review_lecturer: "Lecturer",
  teach_profile_review_rating: "Rating",
  teach_profile_review_review: "Review",

  // Public All Course

  public_all_course_total: "Total",
  public_all_course_course: "course",
  public_all_course_full_list_course: "Full List Course",

  // Instructor Calendar
  in_calendar_January: "January",
  in_calendar_February: "February",
  in_calendar_March: "March",
  in_calendar_April: "April",
  in_calendar_May: "May",
  in_calendar_June: "June",
  in_calendar_July: "July",
  in_calendar_August: "August",
  in_calendar_September: "September",
  in_calendar_October: "October",
  in_calendar_November: "November",
  in_calendar_December: "December",
  in_calendar_number_class_today: "Today's class number",

  // Instructor Profile
  in_profile_person_information: "Person Information",
  in_profile_person_course: "Course",
  in_profile_person_review: "Review",
  in_profile_person_course_id: "Course ID",
  in_profile_person_course_name: "Course Name",
  in_profile_person_course_status: "Status",
  in_profile_person_course_action: "Action",
  in_profile_person_review_student: "Student",
  in_profile_person_review_course_name: "Course Name",
  in_profile_person_review_course_rating: "Rating",
  in_profile_person_review_course_review: "Review",
  in_profile_person_review_course_action: "Action",
  in_profile_person_lecturer_note: "LECTURER NOTE",
  in_profile_person_add_note: "Add note",
  in_profile_person_gender: "Gender",
  in_profile_person_date_of_birth: "Date of birth",
  in_profile_person_phone: "Phone Number",
  in_profile_person_addtion_info: "Addtion infomation",
  in_profile_person_address: "Address",
  in_profile_person_full_name: "Full Name",
  in_profile_person_first_name: "First Name",
  in_profile_person_last_name: "Last Name",
  in_profile_person_email_address: "Email Address",

  // Student List
  student_list_title: "Student List",
  student_list_type: "Type",
  student_list_btn_add: "Add",
  student_list_table_student: "Student",
  student_list_table_enrolled_course: "Enrolled course",
  student_list_table_phone: "Phone number",
  student_list_table_address: "Address",
  student_list_table_type: "Type",
  student_list_table_status: "Status",
  student_list_table_trial: "Trial",
  student_list_table_standard: "Standard",

  // All course
  all_course_title: "All course",
  all_course_export: "Export Report",
  all_course_add_course: "Add course",
  all_course_open_lesson: "Open Lesson",
  all_course_table_course_id: "Course ID",
  all_course_table_course_name: "Course Name",
  all_course_table_course_capacity: "Capacity",
  all_course_table_course_enrolled: "Enrolled",
  all_course_table_course_pending: "Pending",
  all_course_table_course_duration: "Duration",
  all_course_table_course_status: "Status",

  // Not Authorize
  not_authorize_title: "403 - Not Authorize",
  not_authorize_description:
    "Oops, You don't have permission to access this page.",
  not_authorize_back: "Back To Home",

  // Instructor Course detail

  instructor_course_detail_btn_back: "Back",
  instructor_course_detail_btn_add_course: "Add Course",
  instructor_course_detail_btn_add_lesson: "Add Lesson",
  instructor_course_detail_tab_overview: "Overview",
  instructor_course_detail_tab_enrolled: "Enrolled",
  instructor_course_detail_tab_pending: "Pending",
  instructor_course_detail_tab_note: "Note",
  instructor_course_detail_tab_time: "Time",
  instructor_course_detail_tab_prsence: "Presence",
  instructor_course_detail_avatar: "Avatar",
  instructor_course_detail_fullname: "Full Name",
  instructor_course_detail_age: "Age",
  instructor_course_detail_gender: "Gender",
  instructor_course_detail_phone: "Phone Number",
  instructor_course_detail_email: "Email",

  // List Course Search
  not_course: " No matching results were found",
  course_count: "Matching course number found",

  publicswimming_note1_item1:
    "Allows free movement throughout the pool. Swimmers can move around freely, play, and socialize without any specific boundaries.",
  publicswimming_note1_item2:
    "Ideal for leisure and casual use. Great for families, children, and anyone looking to enjoy the water without a structured routine.",
  publicswimming_note1_item3:
    "Tends to be more relaxed and social. The atmosphere is generally more casual, making it ideal for socializing and recreational activities.",
  publicswimming_note2_item1:
    "Confines each swimmer to a specific lane. This setup ensures an organized flow, preventing interference between swimmers and allowing for uninterrupted lap swimming.",
  publicswimming_note2_item2:
    "Perfect for structured exercise and training. Suitable for those aiming to improve their swimming techniques, stamina, and overall fitness.",
  publicswimming_note2_item3:
    "More organized and focused on fitness goals. The structured environment helps swimmers concentrate on their exercise routines and achieve their training objectives.",
  publicswimming_thankyou: "Thank you and enjoy your swimming!",

  //#region Policies
  select_info_sec_policy: "Information security policy",
  select_dispute_and_complaint: "Disputes and complaints",
  select_payment_policy: "Payment policy",
  select_exchange_cancellation: "Exchange, cancellation and refund policy",
  select_online_purchasing_policy: "Online purchasing policy",

  //#region InfoSecPolicy
  info_sec_policy: "PERSONAL INFORMATION PROTECTION POLICY",
  info_sec_policy_section1_description:
    "To ensure the safety of the website and the security of consumer information, we have established the following privacy policies for individuals and organizations when purchasing on the website:",
  info_sec_policy_section1_detail: `This privacy policy explains how we receive, use, and (in some cases) disclose your personal information. The policy will also explain the steps we take to protect our customers' personal information. Finally, the privacy policy will explain your choices regarding the collection, use, and disclosure of your personal information.`,
  info_sec_policy_section2_item1_title: `Purpose and Scope of Information Collection`,
  info_sec_policy_section2_item1_detail1: `The collection of data on the website`,
  info_sec_policy_section2_item1_detail2: `will help us:`,
  info_sec_policy_section2_item1_list1: `Understand the needs and desires of customers to improve product quality.`,
  info_sec_policy_section2_item1_list2: `Help customers update information on promotions and discounts organized by us as soon as possible.`,
  info_sec_policy_section2_item1_list3: `Support customers in case of complaints and disputes in the fastest way.`,
  info_sec_policy_section2_item1_detail3: `COLLECTS THE FOLLOWING INFORMATION FROM CUSTOMERS`,
  info_sec_policy_section2_item1_list4: `During the process of using the services on the website, we store detailed information about the course booking history, learning history, questions, and concerns that need to be supported and answered by customers.`,
  info_sec_policy_section2_item1_detail4: `Customers will be responsible for the security and storage of all service usage activities under their registered name and password. In addition, customers are responsible for promptly notifying the website administrator of any unauthorized use, abuse, security breaches, or storage of third-party registration names and passwords for appropriate solutions.`,
  info_sec_policy_section2_item2_title: "SCOPE OF USE OF INFORMATION",
  info_sec_policy_section2_item2_description:
    "The company uses the information provided by customers to:",
  info_sec_policy_section2_item2_list1: "Provide services to customers.",
  info_sec_policy_section2_item2_list2:
    "Send information about new programs, promotions, and incentives if customers agree to these terms during registration.",
  info_sec_policy_section2_item2_list3:
    "Send notifications about information exchange activities between customers and Aquatics Hub.",
  info_sec_policy_section2_item2_list4:
    "Prevent activities that destroy customers' user accounts or impersonate customers.",
  info_sec_policy_section2_item2_list5:
    "Contact and resolve with customers in special cases.",
  info_sec_policy_section2_item2_list6:
    "Do not use customers' personal information for purposes other than confirmation and contact related to delivery services on the website",
  info_sec_policy_section2_item2_detail:
    "In case of legal requirements: The company is responsible for cooperating to provide customers personal information when requested by judicial authorities including Procuracy, court and police agencies investigating related to any violation of the law by the customer. In addition, no one has the right to infringe on the personal information of customers.",
  info_sec_policy_section2_item3_title: "INFORMATION STORAGE TIME",
  info_sec_policy_section2_item3_detail:
    "The customer's personal data will be stored until there is a request for cancellation. In the remaining cases, in any case, customer's personal information will be kept confidential on the website's server",
  info_sec_policy_section2_item4_title:
    "COMMITMENT TO PROTECTING CUSTOMERS' PERSONAL INFORMATION",
  info_sec_policy_section2_item4_detail1:
    "Customers' personal information on the website",
  info_sec_policy_section2_item4_detail2:
    "is committed to absolute confidentiality according to the website's personal information protection policy. The collection and use of each customer's information is only done with the consent of that customer, except as otherwise provided by law.",
  info_sec_policy_section2_item4_list1:
    "Do not use, transfer, provide or disclose to a third party the personal information of customers without the consent of the customer.",
  info_sec_policy_section2_item4_list2:
    "In the event that the information storage server is attacked by hackers leading to the loss of customer personal data, we will be responsible for reporting the incident to the authorities for timely investigation and handling and notifying customers to know.",
  info_sec_policy_section2_item4_list3:
    "Aquatics Hub requires individuals when using the website to provide full personal information related to Full name, contact address, email, phone number, ...., and take responsibility for the legality of the above information. Aquatics Hub is not responsible and does not resolve any complaints related to customer benefits if it finds that the customer's personal information provided during the initial registration is incorrect.",
  info_sec_policy_section2_item5_title:
    "Organizations that can receive information",
  info_sec_policy_section2_item5_detail1:
    "The business activities of the website",
  info_sec_policy_section2_item5_detail2:
    "do not include selling customer information to third parties. Website",
  info_sec_policy_section2_item5_detail3:
    "only provides customer information to the parties listed below to ensure the interests of customers according to Aquatics Hub's confidentiality commitments:",
  info_sec_policy_section2_item5_list1:
    "Partners who have signed a contract to perform a part of the service for Aquatics Hub (technical partners, partners providing online payment services...). These partners will receive information according to the contract agreement (maybe a part or all of the information depending on the terms of the contract) to proceed to support users in the use of the services provided by the Company.",
  info_sec_policy_section2_item5_list2:
    "State agencies upon request: Aquatics Hub will provide customer information to serve the investigation process of those agencies.",
  info_sec_policy_section2_item6_title:
    "Address of the unit collecting and managing personal information",
  info_sec_policy_section2_item6_list1: "Phone: 0969685445",
  info_sec_policy_section2_item6_list2: "Email: contact@aquaticshub.vn",
  info_sec_policy_section2_item6_list3:
    "Address: 98 To Ngoc Van Street, Quang An Ward, Tay Ho District, Hanoi, Vietnam",
  info_sec_policy_section2_item7_title:
    "Means and tools for users to access and edit their personal data.",
  info_sec_policy_section2_item7_detail1:
    "Customers can exercise the right to edit and update their personal information by contacting us via phone number, email or contact address published at the bottom of the website",
  info_sec_policy_section2_item7_detail2:
    "Or Customers can log in to their member account on the website",
  info_sec_policy_section2_item7_detail3:
    "to directly edit and update their personal information.",
  info_sec_policy_section2_item8_title:
    "Mechanism to receive and resolve consumer complaints related to personal information being used for the wrong purpose or scope of the notice:",
  info_sec_policy_section2_item8_detail1:
    "When consumers discover that their personal information on the website",
  info_sec_policy_section2_item8_detail2:
    "is being used for the wrong purpose or outside the scope of use of information, consumers can send complaints to Aquatics Hub Consulting and Education Joint Stock Company in the following ways:",
  info_sec_policy_section2_item8_list1: "Call the Hotline: 0969685445",
  info_sec_policy_section2_item8_list2:
    "Send a complaint email to the address: contact@aquaticshub.vnn;",
  info_sec_policy_section2_item8_list3:
    "Send a complaint in writing by post or directly to the address: 98 To Ngoc Van Street, Quang An Ward, Tay Ho District, Hanoi, Vietnam",
  info_sec_policy_section2_item9_detail1:
    "The time limit for the Company to handle complaints related to customer personal information is 03 (three) working days, from the date of receiving the customer's complaint.",
  info_sec_policy_section2_item9_detail2:
    "In all cases, the Company promotes negotiation and conciliation with customers to agree and come up with solutions to resolve and handle complaints.",
  info_sec_policy_section2_item9_detail3:
    "In case the two parties do not reach the desired agreement leading to unsuccessful negotiation and conciliation, either party has the right to bring the case to the competent People's Court for settlement according to the provisions of law.",
  //#endregion
  //#region OnlPurPolicy

  onl_pur_policy: "Online Purchasing Policy",
  onl_pur_policy_title1: "To purchase online services on the website",
  onl_pur_policy_title2: "customers can follow the steps below:",
  onl_pur_policy_step1_title1: "Step 1: Customers access the website",
  onl_pur_policy_step1_title2:
    "On the homepage interface, customers can select:",
  onl_pur_policy_step1_detail1:
    "Login: applicable to customers who have registered an account or have been provided an account by the admin.",
  onl_pur_policy_step1_detail2:
    "Register: applicable to customers accessing the website for the first time and who do not have an account or have not created one yet.",
  onl_pur_policy_step1_detail3: `On the "Register an Account" page, customers are required to fill in all the necessary information including Full Name, Phone Number, Email, Password, Confirm Password, Date of Birth, Gender, Address and click "Register an Account."`,
  onl_pur_policy_step2_title:
    "Step 2: After successfully logging in or registering, customers can choose the course they wish to enroll in. The products and services currently available at Aquatics Hub include:",
  onl_pur_policy_step2_list1:
    "School of Fish: Training swimming classes for adults and children based on skill levels.School of Fish: Đào tạo các lớp bơi theo trình độ cho người lớn, trẻ em.",
  onl_pur_policy_step2_list2:
    "Flying Fish: Training classes for competitive swimming.",
  onl_pur_policy_step2_list3:
    "Technique based progression: Technique training swimming classes",
  onl_pur_policy_step2_list4:
    "Fast Track: Swimming packages for holiday periods",
  onl_pur_policy_step2_list5:
    "Public swimming; Lane rental; Pool rental: Rental services for swimming pools, lanes, and tickets",
  onl_pur_policy_step2_list6: "School Group: Swimming training for schools",
  onl_pur_policy_step3_title:
    "Step 3: Customers select the desired semester to register for.",
  onl_pur_policy_step4_title:
    "Step 4: Customers choose the desired course to participate in and proceed with the payment.",
  note: "Note",
  onl_pur_policy_step5_note1: `Within 48 hours of receiving the order information, the system will send a "Confirmation Notice" to the customer's registered email.`,
  onl_pur_policy_step5_note2:
    "In case customers want to change/cancel the order, please contact us directly via the hotline at 0969685445 for detailed support.",
  //#endregion

  // #region Disputes and complaints
  com_policy_title:
    " MEASURES - MECHANISMS - PROCEDURES FOR RESOLVING DISPUTES AND COMPLAINTS",
  com_policy_description_1:
    "Customers agree and acknowledge that, when provided with an account by Aquatics HUB to register for courses or use Aquatics HUB's services, any disputes arising during the customer's use will be resolved in accordance with the current laws of the Socialist Republic of Vietnam.",
  com_policy_description_2:
    "Any complaints arising during the course of study or rental must be submitted to Aquatics HUB or its member units immediately after the incident giving rise to the complaint. Aquatics HUB will resolve customer disputes. Aquatics HUB will consider each specific case to find an appropriate solution. When exercising the right to complain, the complainant is obligated to provide documents, evidence, and grounds related to the complaint and is responsible for the content of the complaint, documents, evidence, and grounds provided by them in accordance with the law.",
  com_policy_description_3:
    "Aquatics HUB only supports and resolves complaints and accusations from customers in cases where customers provide complete, truthful, and accurate information.",
  com_policy_description_4:
    "Customers agree to protect, indemnify, and hold Aquatics HUB harmless from any legal obligations, lawsuits, losses, and expenses including but not limited to court fees, attorney's fees, and consultant fees related to the resolution or arising from the customer's violation during the course of study or use of Aquatics HUB's services.",
  com_policy_title_2: "Procedures for requesting conversion/refund",
  com_policy_step_1: " Step 1:",
  com_policy_step_1_desc: `Receipt: Receive the customer's complaint resolution request (referred to as the "complainant") and check the complaint information to accept or reject.`,
  com_policy_step_2: "Step 2:",
  com_policy_step_2_desc:
    "Complaint handling: Collect relevant information, images, and evidence.",
  com_policy_step_3: "Step 3:",
  com_policy_step_3_desc:
    "Complaint completion: Confirm the results, propose handling methods for each violation, and proceed to close the complaint.",
  com_policy_sp_contact: "SUPPORT CONTACT ADDRESS",
  com_policy_name: "Name",
  com_policy_phone: "Phone",
  com_policy_address: "Address",
  // #endregion

  // #region  Payment policy
  pay_policy_method_2: "Domestic card and bank account",
  pay_policy_method_3: "International payment cards",
  pay_policy_similar_1:
    " Similar to the payment method Domestic card and bank account",
  pay_policy_similar_2: " Similar to the payment method VN PAY-QR",
  pay_policy_title_1: " VN PAY payment instructions",
  pay_policy_desc_1:
    "VN PAY payment gateway is a payment solution developed by Vietnam Payment Solution Joint Stock Company (VN PAY). Customers use the card/bank account, QR Pay/VN PAY-QR feature built into banks' Mobile Banking applications or linked e-Wallets to pay for transactions and enter discount codes (if any)",
  pay_policy_title_2:
    "Scan VN PAY-QR code on 35+ Mobile Banking Applications and 15+ linked e-Wallets",
  pay_policy_title_3: "40+ ATM/domestic cards/bank accounts",
  pay_policy_title_4: "4 International payment cards",
  pay_policy_title_5: "Payment methods via VN PAY",
  pay_policy_support: "VN PAY SUPPORT CHANNEL",
  pay_policy_email: "Email",
  pay_policy_switchboard: "Switchboard",

  pay_policy_confirm_bankCard: "Confirm the cart",
  pay_policy_confirm: "Confirm the cart",
  pay_policy_confirm_desc:
    "You select products and services and select Pay now or Place order At the payment page, please check the ordered product, fill in the consignee information, select VN PAY payment method and press the Order Now button.",
  pay_policy_select_method: "  Select the payment method",
  pay_policy_select_method_desc:
    "The payment screen switches to the VN PAY payment gateway interface. Select the method Domestic card and bank account and select the bank you want to pay with the card in the list",
  pay_policy_verification: "Verification",
  pay_policy_verification_desc:
    "Please enter card/account information as requested and select Continue. The OTP code will be sent to the registered phone, enter the OTP code to complete the transaction",
  pay_policy_verification_note:
    "Note: The transaction will expire after 15 minutes",
  pay_policy_reCheck: "Re-check the information",
  pay_policy_reCheck_desc:
    "When payment is completed, you will receive a notification confirming your successful order on the website",

  pay_policy_confirm_vnpay_qr: " Confirm the cart",
  pay_policy_confirm_desc_bankCard:
    "You choose the product or service and select Pay Now or Order. At the payment page, please check the ordered product, fill in consignee information, select payment method VN PAY and press the Order Now button. .",
  pay_policy_confirm_desc_vnpay_qr:
    "You select products and services and select Pay now or Place order. At the payment page, please check the ordered product, fill in the consignee information, select VN PAY payment method and press the Order now button.",
  pay_policy_select_method_vnpay_qr: "Select the payment method",
  pay_policy_select_method_bankCard: "Select a payment method",
  pay_policy_select_method_desc_bankCard:
    "The payment screen switches to the VN PAY payment gateway interface. Select the method Domestic card and bank account then select the bank you want to pay by card in the list",
  pay_policy_select_method_desc_vnpay_qr:
    "The payment screen switches to the VN PAY payment gateway interface. Select the method Payment application supports VN PAY-QR",
  pay_policy_verification_bankCard: "Verification",
  pay_policy_verification_desc_bankCard:
    "Please enter card/account information as requested and select Continue. The OTP code will be sent to the registered phone, enter the OTP code to complete the transaction",
  pay_policy_scan_vnpay_qr: "Scan the QR Code",
  pay_policy_verification_note_bankCard:
    " Note: The transaction will expire after 15 minutes",
  pay_policy_reCheck_bankCard: "Check the information again",
  pay_policy_reCheck_desc_bankCard:
    "When payment is completed, you will receive a successful order confirmation notification on the website",
  pay_policy_scan_desc_vnpay_qr:
    "The system displays a QR code with the amount to be paid. Please check this amount again. Use the banking application (according to the list), select Scan Code and scan the QR code on the website payment screen.",
  pay_policy_scan_note_vnpay_qr:
    "Note: QR code is valid for 15 minutes For the payment process to be successful, customers please review the conditions in advance and scan the code on your phone to be ready to avoid time-out issues affecting your payment and promotional code.",
  pay_policy_reCheck_vnpay_qr: " Re-check the information",
  pay_policy_reCheck_desc_vnpay_qr: `Check information, enter discount code (if any) and complete payment. When payment is completed, you will receive a notification confirming your successful order on the website`,

  // #endregion

  // #region Refund policy
  ref_policy_title_1: "REGULATIONS ON CONVERSION AND REFUND",
  ref_policy_subtitle_1: "Scope of Application",
  ref_policy_subtitle_2: "Definitions",
  ref_policy_subtitle_3: "Specific Regulations",
  ref_policy_subtitle_4: "Course Conversion",
  ref_policy_subtitle_5: "Course Refund",
  ref_policy_subtitle_6: " Procedures for requesting conversion/refund",
  ref_policy_step_1: "Step 1:",
  ref_policy_step_2: "Step 2:",
  ref_policy_step_3: "Step 3:",
  ref_policy_step_4: "Step 4:",
  ref_policy_desc_1:
    "Applies to customers who register for courses and services (renting the entire pool, renting a lane, renting a technical training area) of Aquatics Hub. Does not apply to customers who purchase single-use tickets.",
  ref_policy_desc_2:
    "Conversion (applies only to courses): Changing from one course to another of equal payment value.",
  ref_policy_desc_3: `Refund: Returning the amount paid to the student (excluding payment service fees if any).`,
  ref_policy_desc_4:
    "Cancellation of course/service: Changing the selection after payment has been made and the transaction is confirmed.",
  ref_policy_desc_5:
    "Difference in tuition fees: Difference in tuition fees = List price of the replacement course/service - List price of the original course/service.",
  ref_policy_desc_6: "The list price is calculated at the time of conversion.",
  ref_policy_desc_7: `In the case where the replacement course/service has a lower payment value than the original course/service, the difference will be reserved for future payments or returned to the customer via bank transfer (Students applying this policy, please contact hotline: 0969685445 for consultation and procedures).`,
  ref_policy_desc_8:
    "Successful course registration time: The time when the student completes the payment process and receives a transaction confirmation notification.",
  ref_policy_desc_9:
    "Start of study time: The time when the student starts participating in the course with confirmation from the teacher and the company's software system starts calculating the % completion of the course.",
  ref_policy_desc_10:
    "Course end date: The time when the student no longer has access to the course.",
  ref_policy_desc_11:
    "Conversion deadline: The time when the student is allowed to convert the original course to a replacement course.",
  ref_policy_desc_12:
    "Students are allowed to convert once only for a successfully registered course. For cases requiring conversion/assistance, please contact the hotline: 0969685445 for support.",
  ref_policy_desc_13:
    "Conditions for conversion include: Within 24 hours of successful registration The completion rate of the program does not exceed 10%",
  ref_policy_desc_14:
    "Students are allowed to refund a successfully registered course. For refund cases, please contact the hotline: 0969685445 for support.",
  ref_policy_desc_15:
    "Conditions for refund include: Within 24 hours of successful registration",
  ref_policy_desc_16: `For services other than courses (such as renting the pool, renting a swimming lane, renting a technical training area):`,
  ref_policy_desc_17:
    "Customers may change the rental schedule depending on the actual conditions of the pool allowing for schedule changes.",
  ref_policy_desc_18:
    "If the rental schedule cannot be changed due to the actual situation of the pool, customers may receive a refund.",
  ref_policy_desc_19:
    "Refund conditions: Within 24 hours of successful registration For any issues, concerns, or need for consultation or assistance, please contact: Aquatics Hub Company via phone number 0969685445 or email: contact@aquaticshub.vn (Support hours from 7.00 to 21.00 daily)",
  ref_policy_desc_20:
    "Contact the Hotline at 0969685445 or via email: at contact@aquaticshub.vn, providing the phone number used to register the student's account and course information.",
  ref_policy_desc_21:
    "Aquatics Hub reviews the request for course conversion or refund and the conditions.",
  ref_policy_desc_22:
    "Aquatics Hub announces the decision to convert the course/refund the tuition. For refund information, Aquatics Hub requests bank information to refund the tuition to the student.",
  ref_policy_desc_23:
    "Aquatics Hub proceeds with the class conversion/refund. The estimated completion time for the refund is within 15 working days from the date of confirmation of eligibility for a refund. Note: To verify the refund information, parents are requested to provide a transfer receipt for Aquatics Hub to check the information and refund according to regulations.",

  // #endregion

  // #region General policy
  Footer_bottom_general_policy: "General Policy",
  select_General_policy: "General Policy",
  general_policy: "General Policies and Regulations",
  general_policy_section1: "1.GENERAL INTRODUCTION",
  general_policy_section1_list1_item1: "Company name ",
  general_policy_section1_list1_item2:
    "GOODLIFE COMPANY LIMITED (Aquatics Hub)",
  general_policy_section1_list2_item1: "Legal representative:",
  general_policy_section1_list2_item2: "Mr. Nguyen Hoang Thai",
  general_policy_section1_list3_item1:
    "Address: 1st Floor, 4-storey building, Quang Ba Trade Union Hotel, 98 To Ngoc Van, Quang An Ward, Tay Ho District, Hanoi City",
  general_policy_section1_list4_item1: "Phone: 0969685445",
  general_policy_section1_list5_item1: "Email: contact@aquaticshub.vn",
  general_policy_section1_list6_item1:
    "Business registration certificate number: 0110496704",
  general_policy_section1_list7_item1: "Date of issue: October 4, 2023",
  general_policy_section2: "2.LIMITED CONDITIONS",
  general_policy_section2_description:
    "Aquatics Hub only receives and resolves requests from individuals and organizations that are Aquatics Hub's customers. In all other cases Aquatics Hub has the right to consider, evaluate and refuse to resolve the request. In case individuals or organizations are refused resolution by Aquatics Hub, they can contact Aquatics Hub directly to find out or work with the competent authority to resolve the issue.",
  general_policy_section3: "3.PRODUCT AND SERVICE STANDARDS",
  general_policy_section3_description:
    "Aquatics Hub's products/services all meet quality standards and are fully licensed according to the provisions of law. If you have any questions about the standards or quality of products/services, please contact Aquatics Hub directly for answers.",
  general_policy_section4: "4.OBLIGATIONS OF RELATED PARTIES",
  general_policy_section4_description1:
    "Aquatics Hub commits to honesty, compliance with legal regulations when providing products/services to customers, and is willing to cooperate with customers to resolve problems",
  general_policy_section4_description2:
    "Aquatics hub customers at the website https://aquaticshub.vn are obliged to comply with the policies listed at the bottom of the website, comply with legal regulations, and cooperate with Aquatics Hub to solve problems that arise",
  general_policy_section4_description3: `We always welcome your comments, contacts and feedback regarding this "General Policy and Regulations". If you have related questions please contact the website owner:`,
  general_policy_section4_list1_item1: "Company name",
  general_policy_section4_list2_item1: "Legal representative",

  general_policy_section4_list3_item1:
    "Address: 1st Floor, 4-storey building, Quang Ba Trade Union Hotel, 98 To Ngoc Van, Quang An Ward, Tay Ho District, Hanoi City",
  general_policy_section4_list4_item1: "Phone: 0969685445",
  general_policy_section4_list5_item1: "Email: contact@aquaticshub.vn",
  general_policy_section4_list6_item1:
    "Business registration certificate number: 0110496704",
  general_policy_section4_list7_item1: "Date of issue: October 4, 2023",
  general_policy_section4_description4:
    "Issued by Hanoi City Business Registration Office",
  // #endregion

  // #region Service coure
  sc_policy_title: "SWIMMING COURSES/SERVICES AT AQUATICS HUB COMPANY",
  sc_policy_section1_title:
    "Swimming Courses/Services at Aquatics Hub Swimming Pool:",
  sc_policy_section1_description:
    "Welcome to Aquatics Hub, a modern 25-meter, 8-lane swimming pool in Hanoi, serving swimmers of all ages and skill levels, bringing lifelong water fun and health to individuals and families.",
  sc_policy_section_2: "GROUP/PRIVATE SWIMMING LESSONS FOR STUDENTS",
  sc_policy_section_2_desciption_1:
    "Aquatics Hub’s swimming lessons follow the “Fish School” method, focusing on swimming techniques developed by professional instructors. This method breaks down learning into manageable steps, providing a structured curriculum to accelerate skill development.",
  sc_policy_section_2_desciption_2:
    "- Courses are offered on a daily, weekly, and term basis for students from kindergarten to university.",
  sc_policy_section_2_desciption_3:
    "Group classes are organized with up to 10 students per class, divided according to the skill level of the participants.",
  sc_policy_section_2_desciption_4:
    "Private lessons are offered in 1-on-1 or 1-on-3 formats.",
  sc_policy_section_2_desciption_5:
    "All classes aim to ensure that students achieve the best skills after each Discover .",
  sc_policy_section_3: "SWIM TEAM TRAINING COURSES",
  sc_policy_section_3_desciption_1:
    "Aquatics Hub trains and coaches students in swim teams for competitions domestically and internationally. Swim teams at Aquatics Hub are divided by age and skill level.",
  sc_policy_section_3_desciption_2:
    "These courses are suitable for students who wish to enhance their skills for competitions",
  sc_policy_section_3_desciption_3:
    "There is no limit on the number of participants for these courses. Training sessions can be daily or weekly, with fees paid in packages.",
  sc_policy_section_4: "SWIMMING COURSES UNDER SCHOOL SPORTS PROGRAMS",
  sc_policy_section_4_description_1:
    "Aquatics Hub also offers swimming programs for school students as part of their physical education curriculum.",
  sc_policy_section_4_description_2:
    "Schools can bring their students to Aquatics Hub, where Aquatics Hub instructors will collaborate with school teachers to develop and teach the swimming program",
  sc_policy_section_4_description_3:
    "Class schedules will be adjusted to fit the students’ school timetable.",
  sc_policy_section_5: "SWIMMING COURSES FOR ADULTS",
  sc_policy_section_5_description_1:
    "Aquatics Hub believes that learning is a lifelong process and that abilities can be achieved at any age! Aquatics Hub's adult swimming classes are open to all levels, from beginners learning to gain confidence in the water to experienced swimmers training regularly or preparing for a triathlon. Aquatics Hub's adult swimming courses include beginner courses; intensive technique courses, swimming fitness courses, and competitive swimming courses. We also offer lifeguarding, swim teacher training, and lifeguarding and swimming teaching certifications. These courses are offered on a daily/weekly/monthly/term basis to easily fit into your schedule.",
  sc_policy_section_6: "FREE SWIMMING/POOL LANE RENTAL/POOL RENTAL SERVICES",
  sc_policy_section_6_description_1:
    "Discover the joy of water at Aquatics Hub! Whether you come for free swimming or swim with family, Aquatics Hub welcomes you. Join us for a vibrant swimming community experience..",
  sc_policy_section_6_description_2:
    "- Available for individual customers, swimming clubs, companies, and businesses looking to purchase free swimming tickets for themselves, their employees, or their organizations.",
  sc_policy_section_7: "TECHNIQUE POOL AND ICE BATH RENTAL SERVICES",
  sc_policy_section_7_description_1:
    "Explore The Aquarium, a revolutionary aquatic sanctuary in Hanoi, that offers an unparalleled indoor swimming experience. Immerse yourself in our dedicated 8-meter training pool, equipped for endless swimming, laps, and technique analysis. Rejuvenating cold therapy is available in the ice bath.",
  sc_policy_section_7_description_2:
    "The technique pool is available for any individuals, swimming clubs, units, companies, or businesses seeking to enhance technique, endurance, relieve stress, and recover health.",
  sc_policy_section_7_description_3:
    "Classes at Aquatics Hub are diverse, with specialized, bilingual instructors.",
  sc_policy_section_7_description_4:
    "Additionally, Aquatics Hub offers swimming lessons at schools, facilities, and units that request our instructors to teach on-site.",
  sc_policy_section_7_description_5:
    "Aquatics Hub’s tuition fees are affordable and tailored to the learning needs of the students.",
  sc_policy_section_8: "SWIMMING LESSON REGISTRATION PROCESS",
  sc_policy_section_8_description_1:
    "Students register directly via the Aquatics Hub hotline:",
  sc_policy_section_8_description_2: " 0969685445 (Admin) or send an email to",
  sc_policy_section_8_description_3:
    "contact@aquaticshub.vn or register directly on the Aquatics Hub website",
  sc_policy_section_8_description_4:
    "- Students provide their name, contact phone number, and chosen course.",
  sc_policy_section_8_description_5:
    "- Students can select their preferred schedule or match their schedule with the instructor.",
  sc_policy_section_8_description_6:
    "- On the first day of class, Aquatics Hub will welcome you at the pool, introduce the instructor, and start the first lesson.",
  sc_policy_section_8_description_7: "Note :",
  sc_policy_section_8_description_8:
    "Students must pay 100% of the tuition fee on the first day of class. Group swimming class tuition includes pool entry fees.",
  sc_policy_section_8_description_9: "COMMITMENT: ",
  sc_policy_section_8_description_10:
    "100% of students will swim correctly according to the technique and training model of each type of class after the course",
  sc_policy_section_8_description_11: "important:",
  sc_policy_section_8_description_12: "Please only contact the phone number",
  sc_policy_section_8_description_13:
    "0969685445 (Admin) or email: contact@aquaticshub.vn",
  sc_policy_section_8_description_14:
    "for swimming lesson registration, before coming to the pool, during the swimming course, and after completing the course. We are not responsible for any issues if you contact other phone numbers, centers, or instructors",
  sc_policy_section_8_description_15:
    "Students can refer to the swimming courses and services on the Aquatics Hub website.:",
  // #endregion

  // #region Select member

  SelectMember_title: "PLEASE SELECT SWIMMER FOR  THE COURSE",
  SelectMember_please_select: "Please select a member",
  SelectMember_main_user: "Main user",
  SelectMember_child: "Member",
  SelectMember_no_child:
    "No child members have been registered yet.Click the button to go to the add member page.",
  SelectMember_Add_member: " Add member",
  SelectMember_next: "Next",

  // #endregion Payments status

  payments_status_success_desc: "Your purchase was successful.",
  payments_status_thankyou: "Thank You!",
  payments_status_appreciate:
    "We appreciate your business and hope you enjoy your new product.",
  payments_status_home: "HOME",
  payments_status_success: "Payment success",
  payments_status_warning: "Apprehensive transaction",
  payments_status_warning_desc:
    "Suspected transactions (related to fraud, unusual transactions)",
  payments_status_incomplete: "Payment incomplete",
  payments_status_bankOutOfOrder: "Bank in maintenance",
  payments_status_AccNotInternetBanking:
    "Account not register Internet Banking",
  payments_status_AccAuthInforWrong3Times:
    "Account information verification error",
  payments_status_paymentExpired: "Payment expired",
  payments_status_AccIsBlocked: "Account is blocked",
  payments_status_WrongOTP: "OTP verification error. Please try again",
  payments_status_TransCancelled: "Transaction cancelled",
  payments_status_AccNotEnounghBalance: "Not enough balance",
  payments_status_MaxTransCapPerDay: "Max transaction capacity per day",
  payments_status_PaymentAuthWrong3Times:
    "Payment verification error. Please try again",
  payments_status_UnknowErrContactAdmin:
    "Something has occured. Please contact admin",
  payments_status_continueRegister: "Continue register ?",
  payments_status_tksForReg:
    "Thank you for your registration. We will contact you shortly",
  payments_status_DONE: "DONE",
  pu_course_detail_addToCart: "Add to cart",

  //#endregion
};

export default EN_TRANSLATE;
